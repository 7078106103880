import { createRouter, createWebHistory } from 'vue-router'
import { constantRoute } from './routes'
const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_CONFIG_BASE),
  routes: constantRoute,
  scrollBehavior() {
    return {
      left: 0,
      top: 0
    }
  }
})

export default router

// rtb参数获取
router.beforeEach((to, from, next) => {
  let userUtm = {}
  if (Object.keys(to.query).length !== 0) {
    const keys = [
      'ad_id',
      'creative_id',
      'pub_id',
      'utm_src_publisher_id',
      'utm_src_app_name',
      'utm_src_page',
      'utm_source',
      'adsource',
      'ad_source',
      'utm_src_type',
      'utm_medium',
      'utm_campaign',
      'utm_content',
      'utm_term',
      'gclid',
      'influencer',
      'ref'
    ]
    keys.forEach((key) => {
      if (to.query[key]) {
        userUtm[key] = to.query[key]
      }
    })
    if (to.query['needrtbid']) {
      localStorage.setItem('collov_rtb_id', to.query['needrtbid'])
    }
    if (Object.keys(userUtm).length !== 0) {
      localStorage.setItem('user_utm', JSON.stringify(userUtm))

      if (userUtm['utm_medium'] === 'email') {
        const time = new Date().getTime()
        const obj = {
          data: userUtm,
          expires: time + 24 * 60 * 60 * 1000
        }
        localStorage.setItem('user_utm_email', JSON.stringify(obj))
      }
    }
    const emailVal = localStorage.getItem('user_utm_email')
    const data = emailVal ? JSON.parse(emailVal) : null
    if (data && data.expires < new Date().getTime()) {
      localStorage.removeItem('user_utm_email')
    }

    let adID = userUtm['ad_id']
    adID = adID && adID !== '' ? adID : userUtm['creative_id']
    adID = adID && adID !== '' ? adID : 'NOT_FROM_RTB'
    let pubID = userUtm['pub_id']
    pubID = pubID && pubID !== '' ? pubID : userUtm['utm_src_publisher_id']
    pubID = pubID ? pubID : ''
    let pubName = userUtm['utm_src_app_name']
    pubName = pubName && pubName !== '' ? pubName : userUtm['utm_src_page']
    pubName = pubName ? pubName : ''
    let source = userUtm['utm_source']
    source = source ? source : ''
    let sourceType = userUtm['utm_src_type']
    sourceType = sourceType ? sourceType : ''
    if (source !== '') {
      // RTB.saveUserID(to)
    }
  } else {
    if (localStorage.getItem('collov_rtb_id')) {
      userUtm = localStorage.getItem('collov_rtb_id')
      let adID = userUtm['ad_id']
      adID = adID && adID !== '' ? adID : userUtm['creative_id']
      adID = adID && adID !== '' ? adID : 'NOT_FROM_RTB'
      let pubID = userUtm['pub_id']
      pubID = pubID && pubID !== '' ? pubID : userUtm['utm_src_publisher_id']
      pubID = pubID ? pubID : ''
      let pubName = userUtm['utm_src_app_name']
      pubName = pubName && pubName !== '' ? pubName : userUtm['utm_src_page']
      pubName = pubName ? pubName : ''
      let source = userUtm['utm_source']
      source = source ? source : ''
      let sourceType = userUtm['utm_src_type']
      sourceType = sourceType ? sourceType : ''
      // RTB.saveUserID(to)
    }
  }
  next()
})
