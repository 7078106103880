<script setup lang="ts">
import {computed, defineProps, withDefaults} from 'vue'
import {svgType} from './svg'

interface Props {
  name: string
  className?: string
  color?: string
  opacity?: string
  width?: string
  bg?: boolean|string
  height?: string
}

const props = withDefaults(defineProps<Props>(), {
  bg: true
})
const iconName = computed(() => `${props.name}`)
const icon = svgType[props.name]
</script>
<template>
  <div style="width: 100%;height: 100%;display: inline-block"
       :style="{'--color':color,'--width':width,'--height':height,'--opacity':opacity}"
       :class="['icon-'+iconName]">
    <span class="bg" v-if="bg"></span>
    <div v-html="icon" style="width: 100%;height: 100%;" class="flex-center"></div>
  </div>
</template>

<style lang="less">
.icon-loading {
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: relative;
}
.bg{
  border-radius: 16px;
  opacity: 0.05;
  background: var(--sonic-silver-300, #44484C);
}
.icon-loading svg {
  width: var(--height, 57px);
  height: var(--width, 57px);
  color: var(--color, #71787F);
  animation: loadingCircle 1s linear infinite;
}

.icon-loading .bg {
  width: calc(~"var(--height,57px) - 4px");
  height: calc(~"var(--height,57px) - 4px");
  border-radius: 50%;
  border: var(--loading-border,4px) solid var(--sonic-silver-20, #E3E4E5);
  position: absolute;
  background: transparent;
  opacity: 1;
  //box-sizing: border-box;
}

@keyframes loadingCircle {
  to {
    transform: rotate(1turn)
  }
}
</style>
