export const form = {
  nonMessage: 'Please enter your full email address and password',
  emailMessage: 'Email enter in the format: name@example.com.',
  passwordMessage: 'password enter 8+ characters with 1 letter and 1 number',
  first: 'First time here? ',
  commentHolder: 'Leave the comment',
  uploadTitle: 'Upload your own room image',
  maskTitle: 'Area of retouch',
  mask: 'Choose area for redesign',
  uploaded: 'Uploaded',
  uploadUrl: 'Uploaded',
  email: 'Email',
  password: 'Password',
  passwordTip: '8+ characters with 1 letter and 1 number',
  style: 'Choose interior design style',
  roomType: 'Choose a Room Type',
  emptyRoom: 'Empty Room?',
  area: 'Choose area for adding item',
  selected: 'Selected',
  productImage: 'Specify furniture style you like',
  colorMaterial: 'Specify the color and material you like',
  mode: 'Choose mode',
  PRESERVED: 'Structure-preserved',
  WELL: 'Well-designed',
  YES: 'Yes',
  NO: 'No',
  PUBLIC: 'Public',
  PRIVATE: 'Private',
  furnishing: 'Furniture',
  teamStyleTitle: 'Your Team Style',
  architectural: 'Architectural',
  'living room': 'Living Room',
  'dining room': 'Dining Room',
  bedroom: 'Bedroom',
  bathroom: 'Bathroom',
    Bathroom: 'Bathroom',
    balcony: 'balcony',
  Bedroom: 'Bedroom',
  'Living room': 'Living room',
  'home office': 'Home Office',
  hallway: 'Hallway',
  kitchen: 'Kitchen',
  outdoor: 'outdoor',
  patio: 'patio',
  'walk-in closets': 'walk-in closets',
  'entrance hall': 'entrance hall',
  'family room': 'family room',
  'study room': 'study room',
  'sun room': 'sun room',
  'game room': 'game room',
  porch: 'porch',
  'laundry room': 'laundry room',
  'storage room': 'storage room',
  garage: 'garage',
  'recreation room': 'recreation room',
  childrenRoom: "children's room",
  'guest room': 'guest room',
  cloakroom: 'cloakroom',
  gym: 'gym',
  'home theater': 'home theater',
  garden: 'garden',
  attic: 'attic',
  basement: 'basement',
  'open office area': 'open office area',
  'meeting room': 'meeting room',
  'lecture hall': 'lecture hall',
  lounge: 'lounge',
  toilet: 'toilet',
  'reception area': 'reception area',
  'reference room': 'reference room',
  'engine room': 'engine room',
  'equipment room': 'equipment room',
  'sales area': 'sales area',
  'dining area': 'dining area',
  warehouse: 'warehouse',
  'locker room': 'locker room',
  'entertainment area': 'entertainment area',
  'checkout counter': 'checkout counter',
  'rest area': 'rest area',
  'waiting area': 'waiting area',
  'display showroom': 'display showroom',
  restaurant: 'restaurant',
  'clothing store': 'clothing store',
  'shoe store': 'shoe store',
  'jewelry store': 'jewelry store',
  'home improvement store': 'home improvement store',
  'beauty shop': 'beauty shop',
  'personal care shop': 'personal care shop',
  'electronics store': 'electronics store',
  bookstore: 'bookstore',
  bohemian: 'Bohemian',
  coastal: 'Coastal',
  farmhouse: 'Farmhouse',
  eclectic: 'Eclectic',
  scandinavian: 'Scandinavian',
  modern: 'Modern',
  transitional: 'Transitional',
  'mid-century': 'Mid-century',
  halloween: 'halloween',
  'new chinese': 'new chinese',
  french: 'french',
  'light luxury': 'light luxury',
  'wabi-sabi': 'wabi-sabi',
  minimalist: 'minimalist',
  'natural wood': 'natural wood',
  creamy: 'creamy',
  'modern minimalist': 'modern minimalist',
  nordic: 'nordic',
  contemporary: 'contemporary',
  industrial: 'industrial',
  country: 'country',
  rustic: 'rustic',
  minimalism: 'minimalism',
  chinese: 'chinese',
  'urban chinese': 'urban chinese',
  retro: 'retro',
  vintage: 'vintage',
  japanese: 'japanese',
  neoclassical: 'neoclassical',
  'american country': 'american country',
  soft: 'soft',
  'art deco': 'art deco',
  'hi-tech': 'hi-tech',
  italian: 'italian',
  'mid-century modern': 'mid-century modern',
  'shabby chic': 'shabby chic',
  japandi: 'japandi',
  colonial: 'colonial',
  'hollywood regency': 'hollywood regency',
  'regency moderne': 'regency moderne',
  'mediterranean revival': 'mediterranean revival',
  rococo: 'rococo',
  'late baroque': 'late baroque',
  'hollywood glam': 'hollywood glam',
  mediterranean: 'mediterranean',
  southwestern: 'southwestern',
  'french country': 'french country',
  'asian zen': 'asian zen',
  'modern farmhouse': 'modern farmhouse',
  african: 'african',
  amish: 'amish',
  arabian: 'arabian',
  'art moderne': 'art moderne',
  'art nouveau': 'art nouveau',
  artisan: 'artisan',
  'arts & crafts': 'arts & crafts',
  baroque: 'baroque',
  cuban: 'cuban',
  gothic: 'gothic',
  moroccan: 'moroccan',
  indian: 'indian',
  tropical: 'tropical',
  biophilic: 'biophilic',
  cottagecore: 'cottagecore',
  maximalist: 'maximalist',
  gaming: 'gaming',
  cyberpunk: 'cyberpunk',
  vaporwave: 'vaporwave',
  'ski chalet': 'ski chalet',
  tribal: 'tribal',
  medieval: 'medieval',
  easter: 'easter',
  christmas: 'christmas',
  'chinese new year': 'chinese new year',
  'hot pink': 'hot pink',
  neoclassic: 'neoclassic',
  victorian: 'victorian',
  /*房间类型分类*/
  'Room Type': 'Room Type',
  'Kitchen Styles': 'Color & Material',
  'Primary Living Spaces': 'Primary Living Spaces',
  'Common Areas': 'Common Areas',
  Outdoor: 'Outdoor',
  'Special Purpose Rooms': 'Special Purpose Rooms',
  'Additional Spaces': 'Additional Spaces',
  'Office Spaces': 'Office Spaces',
  'Public area': 'Public Areas',
  'Commercial and Retail Spaces': 'Commercial and Retail Spaces',
  /*风格类型分类*/
  Floor: 'Floor',
  Wall: 'Wall',
  wood1: 'wood1',
  wood2: 'wood2',
  wood3: 'wood3',
  wood4: 'wood4',
  Brume: 'Brume',
  Indaco: 'Indaco',
  Rubino: 'Rubino',

  Festival: 'Festival',
  Classics: 'Classics',
  International: 'International',
  Modern: 'Modern',
  Scandivian: 'Scandivian',
  Industrial: 'Industrial',
  Farmhouse: 'Farmhouse',
  Retro: 'Retro',
  Historical: 'Historical',
  Glam: 'Glam',
  Gaming: 'Gaming',
  'Mid-Century': 'Mid-Century',
  Transitional: 'Transitional',
  Tropical: 'Tropical',
  Eclectic: 'Eclectic',
  other: 'Other',

  name: 'Name',
  greeting: 'Greeting',
  instruction: 'Instruction',
  conversationStarters: 'Conversation starters',
  greetingMessage: 'First message from your character.',
  nameMessage: 'First message from your character',
  describeMessage: 'Describe your creation',

  LastName: 'Last Name',
  LastNameInput: 'Please input your last name.',
  FirstName: 'First Name',
  FirstNameInput: 'Please input your first name',
  Username: 'Username',
  UsernameMessage: 'Usernames can contain only letters (a-z), numbers (0-9), underscore (_), dash (-), and periods (.)',
  Description: 'Description',
  Capabilities: 'Capabilities',
  DescriptionDes: 'Describe the character’s persona here',
  DescriptionMessage: 'Describe your style.',
  SocialConnection: 'Social Connection',
  emailInput: 'Please input your  email.',
  search: 'Search',
  messageusername: 'Usernames can contain only letters (a-z), numbers (0-9), underscore (_), dash (-), and periods (.)',
  messagefirstName: 'Please input your first name.',
  messagelastName: 'Please input your last name.',
  coverImageRules: 'Please select coverImage.',
  nameRules: 'Please input name.',
  descriptionRules: 'Please input description.',
  greetingRules: 'Please input greeting.',
  instructionRules: 'Please input instruction.',
  ProductName: 'Product Name',
  Brand: 'Brand',

  viewAllProducts: 'View all products',
  filter: 'Filter',
  done: 'Done',
  viewAll: 'View All',
  backToTop: 'Back To Top',
  clearFilter: 'Clear filter',
  // 产品属性
  Color: 'Color',
  ProductType: 'Product type',
  Material: 'Material',
  Finish: 'Finish',
  Size: 'Size',
  WholeRoomRedesign: 'Whole Room Redesign',
  PartialRemodel: 'Partial Remodel',
  'kid’s room': 'Kid’s Room'
}
