import request from '@/utils/request'
const formatParam = function (params: any) {
  return {
    params: params
  }
}
export const requestGetRecord = (id: string) =>
  (request as any).get('/aiGenerateRecord/getRecord', formatParam({ id }))
export const requestGetRecordBase = (id: string) =>
  (request as any).get('/aiGenerateRecord/getRecordBase', formatParam({ id }))
export const getRecordBase = (id: string) =>
  (request as any).get('/aiGenerateRecord/getRecordBase', formatParam({ id }))
export const requestPublishPage = (params) =>
  (request as any).get('/aiGenerateRecord/publishPage', formatParam(params))
export const requestGetEditRecord = (params) =>
  (request as any).get('/aiGenerateRecord/getEditRecord', formatParam(params))
export const requestGetUpScaleUrl = (params) =>
  (request as any).get('/aiGenerateRecord/getUpScaleUrl', formatParam(params))
export const requestQueryLineUp = (params) =>
  (request as any).get('/aiGenerateRecord/queryLineUp', formatParam(params))
export const requestSelectImage = (params) =>
  (request as any).post('/aiGenerateRecord/selectImage', params)
export const requestGenerateImage = (params) =>
  (request as any).post('/aiGenerateRecord/generateImage', params)
export const getBeforeAfterVideo = (params) =>
  (request as any).uploadBlob('/fastGenerateRecord/manage/getBeforeAfterVideo', params)
export const getBeforeAfterGif = (params) =>
  (request as any).uploadBlob('/fastGenerateRecord/manage/getBeforeAfterGif', params)
export const realtimeCustomerStyleSave = (params) =>
  (request as any).post('/realtimeCustomerStyle/manage/save', params)
export const realtimeCustomerStyleUse = (params) =>
  (request as any).post('/realtimeCustomerStyle/manage/use', params)
export const realtimeCustomerStyleSearch = (params) =>
  (request as any).post('/realtimeCustomerStyle/search', params)
export const requestSaveMagicEraser = (params) =>
  (request as any).post('/aiGenerateRecord/saveMagicEraser', params)
export const requestUseReference = (params) =>
  (request as any).post('/aiGenerateRecord/manage/useReference', params)
export const requestListKitchenStyles = (params) =>
  (request as any).get('/common/manage/cabinetsStyles', formatParam(params))
export const requestGetAllStyleList = () =>
  (request as any).get('/aiGenerateRecord/getAllStyleList')
export const requestListStyles = (params) =>
  (request as any).get('/clientProfileStyle/listStyles', formatParam(params))
export const cabinetComment = (params) =>
  (request as any).post('/companyCooperation/cabinetComment', params)
export const requestSubCreditsForRealtime = (params) =>
  (request as any).post('/aiGenerateRecord/manage/subCreditsForRealtime', params)
export const requestPublishRealtime = (params) =>
  (request as any).post('/aiGenerateRecord/manage/publishRealtime', params)
export const requestFindUrls = (params) =>
  (request as any).post('/fastGenerateRecord/findUrls', params)
export const requestSelectImageForFastMultiple = (params) =>
  (request as any).post('/aiGenerateRecord/manage/selectImageForFastMultiple ', params)
export const requestRefine = (params) =>
  (request as any).post('/fastGenerateRecord/refine ', params)
export const requestListSketchCategory = () => (request as any).get('/common/listSketchCategory')
export const requestListSketchImages = (params) =>
  (request as any).post('/common/listSketchImages', params)
// export const requestGenerateEdit = (params) => (request as any).upload('/fastGenerateRecord/manage/generateEdit',params)
export const requestGenerateEdit = (params) =>
  (request as any).upload('/fastGenerateRecord/manage/generateEdit', params)
export const requestMagicBrush = (params) =>
  (request as any).upload('/fastGenerateRecord/manage/magicBrush', params)
export const requestRunPredict = (params) => (request as any).runPredict(params)
export const requestFastUpscale = (params) =>
  (request as any).upload('/fastGenerateRecord/manage/fastUpscale', params)
export const requestRefineUrl = (params) =>
  (request as any).upload('/fastGenerateRecord/refineUrl', params)
export const selectImageForRealtime = (params) =>
  (request as any).upload('aiGenerateRecord/manage/selectImageForRealtime', params)
export const selectImageForRealtimeNew = (params) =>
  (request as any).upload('aiGenerateRecord/manage/selectImageForRealtimeNew', params)

export const requestListPosition = (params) =>
  (request as any).get('/common/listPosition', formatParam(params))
export const requestGenerateMaskUrls = (params) =>
  (request as any).post('/aiGenerateRecord/generateMaskUrls', params)
export const checkRealtimeCountRemainCount = () =>
  (request as any).get('/aiGenerateRecord/manage/checkRealtimeCountRemainCount')
export const requestGetMaskUrls = (params) =>
  (request as any).get('/aiGenerateRecord/getMaskUrls', formatParam(params))
export const requestListFurnitureForNewAi = (params) =>
  (request as any).post('/case/listFurnitureForNewAi', params)
export const requestListEditImages = (id) =>
  (request as any).post('/aiGenerateRecord/listEditImages', { id })
export const requestSearchComment = (params) =>
  (request as any).get('/designModel/searchComment', formatParam(params))
export const requestSaveComment = (params) =>
  (request as any).post('/designModel/manage/saveComment', params)
export const requestGetMember = () => (request as any).get('/clientMember/getMember')
export const requestSaveModel = (params) =>
  (request as any).post('/designModel/manage/save', params)
export const requestTrain = (params) => (request as any).post('/designModel/manage/train', params)
export const requestPublish = (params) =>
  (request as any).post('/designModel/manage/publish', params)
export const checkRealtimeClose = () =>
  (request as any).get('/aiGenerateRecord/manage/checkRealtimeClose')
export const setRunTime = (params) => (request as any).post('/common/setRunTime', params)
export const requestGetTrainRecord = (params) =>
  (request as any).get('/designModel/manage/getTrainRecord', formatParam(params))
export const requestOidGlGenerate = (params) =>
  (request as any).post('/outerClient/record/manage/add', params)
export const requestAddGood = (params) =>
  (request as any).post('/enterpriseGoods/design/manage/addGood', params)
export const requestDeleteGood = (params) =>
  (request as any).post('/enterpriseGoods/design/manage/delete', params)
export const reqWallFloors = (params) =>
  (request as any).get('/aiGenerateRecord/manage/getWallFloors', formatParam(params))
export const listRecordByIds = (params) =>
  (request as any).post('/aiGenerateRecord/listRecordByIds', params)

export const goodCollectPage = (params) =>
  (request as any).get('/goods/manage/goodCollectPage', formatParam(params))
export const saveCollect = (params) => (request as any).post('goods/manage/saveCollect', params)
export const recordGoodsHis = (params) =>
  (request as any).get('aiGenerateRecord/manage/recordGoodsHis', formatParam(params))
export const getStyleListByGenerationType = (params) =>
  (request as any).get('common/manage/getStyleListByGenerationType', formatParam(params))
export const delRecordGood = (params) =>
  (request as any).post('aiGenerateRecord/manage/delRecordGood', params)
export const addGoodsRecord = (params) =>
  (request as any).post('/aiGenerateRecord/manage/addGoods', params)
export const getByRequestId = (params) =>
    (request as any).get('designModel/getByRequestId', formatParam(params))
export const getEnterpriseModelByRequestId = (params) =>
    (request as any).get('designModel/getEnterpriseModelByRequestId', formatParam(params))
export const getfilterList = (params) =>
  (request as any).get('/common/manage/filterList', formatParam(params))
export const apiUploadBase64 = (base64) =>
  (request as any).post('/file/uploadBase64', { base64, referType: 'OTHER' })

