import { message } from '../modules/message'
import { description } from '../modules/description'
import { menu } from '../modules/menu'
import { title } from '../modules/title'
import { form } from '../modules/form'
import { designForm } from '../modules/designForm'
import { allInOneForm } from '../modules/allInOneForm'
import { btn } from '../modules/btn'
import { upgrade } from '../modules/upgrade'
import { pricing } from '../modules/pricing'
import { realtime } from '../modules/Reatime'
import { enterprise } from '../modules/enterprise'
import { confirm } from '../modules/confirm'
import { modal } from '../modules/modal'
import { client } from '../modules/client'
import { member } from '../modules/member'
import { plan } from '../modules/plan'
import { contact } from '../modules/contact'
import { pending } from '../modules/pending'
import { designAi } from '../modules/designAi'
import { uploadImage } from '../modules/uploadImage'
import { payment } from '../modules/payment'
import wallpaperDesign from '../modules/wallpaperDesign'
import furnitureReplacement from '../modules/furniture-replacement'
import { floorDesign } from '../modules/floorDesign'
import homeRedesign from '../modules/homeRedesign'
import aiHomeRedesign from '../modules/ai-home-redesign'
import chat from '../modules/chat'

export const zhLang = {
  payment,
  uploadImage,
  designAi,
  title,
  form,
  designForm,
  allInOneForm,
  btn,
  menu,
  member,
  message,
  description,
  upgrade,
  pricing,
  enterprise,
  confirm,
  modal,
  client,
  plan,
  contact,
  realtime,
  pending,
  wallpaperDesign,
  furnitureReplacement,
  floorDesign,
  homeRedesign,
  aiHomeRedesign,
  chat
}
