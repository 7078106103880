export default {
  tabs: {
    list: ['Photo', 'Text','Floor Plan'],
    text: {
      title: 'Talk to our Chat Designer',
      placeholder: 'Send a message...',
      btn1: 'Redesign my room',
      btn2: 'Design my living room'
    }
  },
  ChatSide: {
    NewChat: 'New Chat',
    deleteChat: 'Are you sure to delete this?'
  },
  ChatContent: {
    history: {
      back: 'back chat',
      history: 'history'
    },
    ChatCurrent: {
      back: 'Chat Designer',
      placeholder: 'Send a message...'
    },
    EmptyCurrent: {
      title: 'Unlock the power of AI',
      subTitle: 'Chat with the Easyexpo AI - Experience the power of AI with us',
      list: ['Help me design a simple booth', 'Design a booth with a table and chairs', 'Design a 3M*6M booth with tech style']
    }
  }
}
