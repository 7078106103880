export const designAi = {
    Exclusive: '独家',
    // KBISONLYPILOT: 'KBIS唯一试点',
    KBISONLYPILOT: '¥800',
    KBISONLYPILOTUNIT: '/月（按年计费）',
    forThe1stMonth: '首月',
    dateTime: '至2024年3月1日',
    AllInOneToolkit: '一体化工具包',
    experienceAI: '体验唯一的由AI驱动、 <span style="color: #ff7e33;">供应商供货的橱柜 </span> 渲染平台。',
    fullCapabilities: '解锁Collov AI的 <span style="color: #ff7e33;">全部功能</span>。',
    oneStyle: '从您的SKU中定制化训练一款风格<span style="color: #ff7e33;">一款风格</span> ，获得您更具个性化的细节。',
    JoinNow: '现在加入',
    TryDemo: '测试demo',
    CabinetDesignTitle: '使用AI革新橱柜设计',
    CabinetDesignName: '世界上第一个也是唯一一个真实的AI设计平台，能够设计出供应商定制的橱柜效果图。',
    DesignAndSales: '连接 <span style="color: #ff7e33">设计</span> 与 <span style="color: #ff7e33">销售</span>的桥梁',
    DesignAndSalesDesc: '将AI设计与真实产品销售结合，从而缩短设计到销售的漫长周期,Collov AI加速采购决策与实现，转化为可购买的橱柜设计。这种双重功能不但提高了销售效率，缩短了销售周期，还能快速将设计转化为收入。',
    SalesEfficiencyRate: '提高60%',
    SalesEfficiency: '销售效率和收入',
    DesignCostRate: '减少50%',
    DesignCost: '室内设计成本',
    CustomerEngagementRate: '增加40%',
    CustomerEngagement: '客户互动率',
    collovApp: '看见Collov AI的真实应用',
    coolovHandle: '拍一张你房间的照片，上传到Collov。只需简单的步骤，人工智能即刻准确地重现专属于你的橱柜细节。',
    digitalTitle: '为您的产品创造别具匠心的设计与销售体验',
    digitalSubTitle: '在Collov平台上，有你需要的所有工具帮助您展示和销售您的产品。',
    digitalSku: '从SKU到数字资产',
    digitalName: `Collov的专属AI先从您的SKU数据中学习，将橱柜转换为精确的数字资产。这个过程确保了每个产品的独特细节被准确地复刻，为现实的设计可视化奠定了基础。`,
    lifelikeName: '现实橱柜在AI设计中实现逼真的渲染',
    lifelikeInfo: `Collov的专属AI可以准确地呈现橱柜设计，以数字形式捕捉每个细节，风格和材料。这一步确保了人工智能设计真实和准确的可视化，促进更明智的设计选择。`,
    seamlessName: '设计和销售的完美集成',
    seamlessInfo: '无论是通过几行代码嵌入您的网站，还是将Collov AI办公桌纳入您的展厅，Collov都能毫不费力地将AI能力集成到您的线上和线下工作流程中。我们的解决方案提高了所有平台的客户参与度，最大程度上确保您与客户的互动体验。',
    scalableName: '在金牌橱柜上的可扩展AI设计',
    scalableInfo: `通过在全球门店试点部署Collov AI, 金牌橱柜在设计和销售业绩方面获得了显著改善。`,
    ReadFullStory: '阅读完整成功故事',
    manufacturerTitle: '零售商合作伙伴试点',
    manufacturerSubtitle: '加入MPP将您的产品转换为数字资产，可以随时访问您的零售网络及客户。当房主在他们的房间里看到你的产品时，房主的购买意愿会增加5倍——这对你和你的零售伙伴来说意味着更多的销售机会！',
    manufacturerToolPopularity: '提高品牌知名度', 
    manufacturerToolRetailer: '增强零售商关系', 
    manufacturerToolChannel: '全渠道销售', 
    LearnMore: '了解更多',
    SubmitBusinessInterest: '合作咨询',
    planTitle: '选择最适合你的计划',
    WhatOurClientsSay: '我们的客户怎么说',
    clientsSay: [
        {name:'简·多伊',arrdress:'现代内饰首席执行官',content:'将Collov AI集成到我们的在线平台中，改变了我们展示橱柜的方式。效果图的准确性和真实性显著改善了我们客户的购物体验，带来了更高的满意度和销售额的增长。'},
        {name:'风中奇缘',arrdress:'优雅空间总监',content:'Collov AI的技术已经改变了我们展厅的游戏规则。在各种环境中准确可视化产品的能力不仅增强了我们的设计流程，还增强了客户对其购买决策的信心。'},
        {name:'艾米丽·怀特',arrdress:'橱柜角销售经理',content:'Collov AI与我们的销售工作流程的无缝集成彻底改变了我们与客户互动的方式。它不仅仅是一个设计工具；这是一个全面的解决方案，支持我们的在线和离线销售策略。'},
        {name:'迈克尔·布朗',arrdress:'DesignFirst Kitchens创始人',content:'我们在Collov AI方面的经验非常出色。人工智能橱柜的细节和准确性在行业中树立了一个新的标准，使我们能够为客户提供独特而身临其境的购物体验。'},
        {name:'莉莎·格林',arrdress:'FutureHome Cabinetry产品经理',content:'Collov AI将我们的橱柜产品提升到了一个新的水平。这项技术能够学习我们的SKU并以如此精确的精度复制每一个细节，使其成为我们设计和销售过程中不可或缺的一部分。'},
    ],
    allinone: {
      unselectDot: '请先选中标记白点再进行物品替换!',
    },
};
