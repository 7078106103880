<script setup lang="ts">
import {clientStore} from "@/stores/client";
import {onMounted} from "vue";
import {initTheme} from '@/hooks/util'
import { setAllInOneMode } from "pages/allInOne/utils/hooks/adapter";
const client = clientStore()
// client.Login()
client.IntegrateLogin()
client.saveView('SESSION')
onMounted(()=>{
  window.callback = function (auth:string) {
    const client = clientStore()
    client.oauthLoginCallback({type: 'login', auth: auth})
  };
  initTheme()
  setAllInOneMode()
})
</script>
<template>
  <RouterView />
</template>
<style>
html,body{
  overflow: hidden;
}
</style>
